import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';

/**Liberia de tailwinds */
import { Transition } from '@headlessui/react'
import { toast } from 'react-toastify';
import { toastOptions, validError } from '../../constants/helpers';
import { endpoint } from '../../constants/endpoint';
import { AutocompleteSector } from '../../components/autocomplete/AutocompleteSector';

export const FormularioRegistro = () => {

    /**Manejo de dato en los inputs y el submit */
    const { register, handleSubmit, formState: { errors }, reset, watch } = useForm();

    /** Estado de Cargando */
    const [cargando, setCargando] = useState(false);
    /**cargando del numero de telefono */
    const [cargandoTelefono, setCargandoTelefono] = useState(false)
    /**Obtener las parroquias y sectores  */
    const [parroquias, setParroquias] = useState([]);
    const [buscarSector, setBuscarSector] = useState([])
    /**Mostrar */
    const [mostrar, setMostrar] = useState(false)
    /**numero de telefono */
    const [numeroDeBusqueda, setNumeroDeBusqueda] = useState('')
    /**Valor para el submit del autocomplete */
    const [valueSector, setValueSector] = useState(parroquias.sector);

    const getParroquiasSectores = async () => {
        const url = `${endpoint}/sector2/?format=json`;
        await axios.get(url, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            setParroquias(response.data)
        }).catch((err) => {
            console.log(err, ' aqui esta el error ')
        })
    }

    useEffect(() => {
        getParroquiasSectores();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**Funcion de submit para el registro */
    const onSubmit = async (data) => {
        setCargando(true)
        let resultados = {};
        if(valueSector != undefined){
            resultados = { ...data, parroquia: valueSector.data[0].id, sector: valueSector.sector_id }
        }else{
            resultados = {...data};
        }
        const url = `${endpoint}/cliente_public/`
        await axios.post(url, resultados, {
            headers: { 'Content-Type': 'application/json; charset=UTF-8' }
        }).then((res) => {
            toast.success(`Registro satisfactorio!`, toastOptions);
            reset()
            setCargando(false)
        }).catch((err) => {
            setCargando(false)
            console.log(err)
            const error = validError(err.response.data)
            toast.warning(`Opps, Ha ocurrido un error, ${error}`, toastOptions);
            setCargando(false)
        })
    }

    // const handleChange = (event) => {
    //     // eslint-disable-next-line eqeqeq
    //     let sectores = parroquias.find(n => n.parroquia_id == event.target.value);
    //     setBuscarSector(sectores.data);
    //     setMostrar(true)
    // };

    const buscarNumero = async () => {
        setCargandoTelefono(true)
        const url = `${endpoint}/cliente_public/?search=${watch('phone')}`
        await axios.get(url).then((response) => {
            if (response.data[0].phone.length > 0) {
                toast.warning(`El numero ${response.data[0].phone} ya esta registrado`, toastOptions);

            }
            setCargandoTelefono(false)
        }).catch((err) => {
            if (watch('phone').length > 11) {
                toast.warning(`El numero ingresado tiene mas de 11 digitos`, toastOptions);

            }
            if (watch('phone').length === 11) {
                toast.info(`El numero ${watch('phone')}, no ha sido registrado`, toastOptions);

            }
            setCargandoTelefono(false)
        })
    }

    useEffect(() => {
        if (watch('phone').length > 10) {
            buscarNumero();
        }
    }, [watch('phone')])

    return (

        <form onSubmit={handleSubmit(onSubmit)} className='sm:w-2/3 w-full px-4 lg:px-0 mx-auto flex flex-col space-y-4'>

            <div className='mb-3 pt-2 rounded bg-gray-200'>
                <label className='block text-gray-700 text-sm font-bold mb-2 ml-3 mr-3'>Teléfono  <span className='text-gray-dark text-xs font-medium'> - (Ej. 0414999999 )</span> </label>
                <input
                    id='phone'
                    type='number'
                    placeholder='Ingrese un Teléfono'
                    className='bg-gray-200 rounded w-full text-gray-700 focus:outline-none placeholder:text-center border-b-4 border-gray-300 focus:border-gray-600 transition duration-500 px-3 pb-2'
                    {...register('phone', { required: true })}

                />
                {errors.phone && (
                    <div className='text-red-900 p-1 text-sm'>Debe ingresar un Teléfono</div>
                )}
            </div>



            {/* <div className=' pt-3 rounded bg-gray-200'>
                <label className='block text-gray-700 text-sm font-bold mb-2 ml-3 mr-3'>Correo Electrónico <span className='text-gray-dark text-xs font-medium'> - (Ej. ejemplo@gmail.com )</span></label>
                <input
                    id='name'
                    type='email'
                    placeholder='Ingrese un correo electrónico'
                    className='bg-gray-200 rounded w-full text-gray-700 focus:outline-none border-b-4 border-gray-300 placeholder:text-center focus:border-gray-600 transition duration-500 px-3 pb-2'
                    {...register('email')}
                />
                {errors.email && (
                    <Fragment>
                        {errors.email.type === 'required' && <div className='text-red-900 p-1 text-sm'>El correo es obligatorio</div>}
                        {errors.email.type === 'pattern' && <div className='text-red-900 p-1 text-sm'>{errors.email.message}</div>}
                    </Fragment>
                )}
            </div> */}


            <div className=' pt-3 rounded bg-gray-200'>
                <label className='block text-gray-700 text-sm font-bold mb-2 ml-3 mr-3' >Nombre y Apellido</label>
                <input
                    id='name'
                    type='text'
                    placeholder='Ingrese su nombre y apellido'
                    {...register('name')}
                    className='bg-gray-200 rounded w-full text-gray-700 placeholder:text-center focus:outline-none border-b-4 border-gray-300 focus:border-gray-600 transition duration-500 px-3 pb-2'
                />
                {errors.name && (
                    <div className='text-red-900 p-1 text-sm'>Debe ingresar su nombre y apellido</div>
                )}
            </div>

            <div className=' pt-3 rounded bg-gray-200'>
                <label className='block text-gray-700 text-sm font-bold mb-2 ml-3 mr-3'>Sector</label>
                <AutocompleteSector parroquias={parroquias} valueSector={valueSector} setValueSector={setValueSector} />

            </div>

            {/* <div className='mb-3 py-2 rounded bg-gray-200'>
                <label className='block text-gray-700 text-sm font-bold mb-2 ml-3 mr-3'>
                    <span className='text-gray-700'>Parroquia</span>
                    <select
                        className='mt-1 form-select block w-full rounded-2xl '
                        name='parroquia'
                        id='parroquia'
                        {...register('parroquia')}
                        onChange={handleChange}
                    >
                        <option value=''>Seleccione</option>
                        {parroquias.map((item) => (
                            <option value={item.parroquia_id} key={item.parroquia_id}>
                                {item.parroquia}
                            </option>
                        ))}


                    </select>

                </label>
                {errors.parroquia && (
                    <div className='text-red-900 p-1 text-sm'>Seleccione una parroquia</div>
                )}
            </div>


            <Transition
                as={Fragment}
                show={mostrar}
                enter='transition ease-out duration-400'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
            >
                {buscarSector.length > 0 && (
                    <div className='mb-3 py-2 rounded bg-gray-200'>
                        <label className='block text-gray-700 text-sm font-bold mb-2 ml-3 mr-3'>
                            <span className='text-gray-700'>Sector</span>
                            <select
                                className='mt-1 form-select block w-full rounded-2xl '
                                name='parroquia'
                                id='parroquia'
                                onChange={handleChange}
                                {...register('sector')}
                            >
                                <option value=''>Seleccione</option>
                                {buscarSector.map((item) => (
                                    <option value={item.id} key={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>
                )}
            </Transition> */}



            <button
                disabled={cargando}
                className='bg-gray-900 hover:bg-gray-700 text-white font-bold py-2 rounded shadow-lg hover:shadow-xl transition duration-200'
                type='submit'
            >
                {cargando ? 'Registrando...' : 'Registrar'}
            </button>




        </form>

    )
}
