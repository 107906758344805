import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';


import { toast } from 'react-toastify';
import { toastOptions, validError } from '../../constants/helpers';
import { endpoint } from '../../constants/endpoint';

export const FormularioConctat = () => {

    /**Manejo de dato en los inputs y el submit */
    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    /** Estado de Cargando */
    const [cargando, setCargando] = useState(false);

    /**Funcion de submit para el registro */
    const onSubmit = async (data) => {
        console.log(data)
        setCargando(true)
        const url = `${endpoint}/email/`
        await axios.post(url, data, {
            headers: { 'Content-Type': 'application/json; charset=UTF-8' }
        }).then((res) => {
            toast.success(`Estimado(a) ${data.usuario} su sugerencia fue enviada, pronto sera atendido`, toastOptions);
            reset()
            setCargando(false)
        }).catch((err) => {
            const error = validError(err.response.data)
            toast.warning(`Opps, Ha ocurrido un error, ${error}`, toastOptions);
            setCargando(false)
        })
    }


    return (

        <form onSubmit={handleSubmit(onSubmit)} className='sm:w-2/3 w-full px-4 lg:px-0 mx-auto flex flex-col space-y-4'>
            <div className=' pt-3 rounded bg-gray-200'>
                <label className='block text-gray-700 text-sm font-bold mb-2 ml-3 mr-3' >Nombre y Apellido</label>
                <input
                    id='usuario'
                    type='text'
                    placeholder='Ingrese su nombre y apellido'
                    {...register('usuario', { required: true })}
                    className='bg-gray-200 rounded w-full text-gray-700 placeholder:text-center focus:outline-none border-b-4 border-gray-300 focus:border-gray-600 transition duration-500 px-3 pb-2'
                />
                {errors.usuario && (
                    <div className='text-red-900 p-1 text-sm'>Debe ingresar su nombre y apellido</div>
                )}
            </div>

            <div className=' pt-3 rounded bg-gray-200'>
                <label className='block text-gray-700 text-sm font-bold mb-2 ml-3 mr-3'>Correo Electrónico <span className='text-gray-dark text-xs font-medium'> - (Ej. ejemplo@gmail.com )</span></label>
                <input
                    id='email'
                    type='email'
                    placeholder='Ingrese un correo electrónico'
                    className='bg-gray-200 rounded w-full text-gray-700 focus:outline-none border-b-4 border-gray-300 placeholder:text-center focus:border-gray-600 transition duration-500 px-3 pb-2'
                    {...register('email', { required: true })}
                />
                {/* {errors.email && (
                    <Fragment>
                        {errors.email.type === 'required' && <div className='text-red-900 p-1 text-sm'>El correo es obligatorio</div>}
                        {errors.email.type === 'pattern' && <div className='text-red-900 p-1 text-sm'>{errors.email.message}</div>}
                    </Fragment>
                )} */}
            </div>


            <div className='rounded bg-gray-200'>
                <textarea
                    id='asunto'
                    placeholder='Escribe tu sugerencia'
                    className='bg-gray-200 w-full text-gray-700 focus:outline-none  border-gray-300  focus:border-gray-600 transition duration-500 px-3'
                    {...register('asunto', { required: true })}

                />
                {errors.asunto && (
                    <div className='text-red-900 p-1 text-sm'>Ingresa tu sugerencia</div>
                )}

            </div>
            <button
                disabled={cargando}
                className='bg-gray-900 hover:bg-gray-700 text-white font-bold py-2 rounded shadow-lg hover:shadow-xl transition duration-200'
                type='submit'>
                {cargando ? 'Enviando...' : 'Enviar'}
            </button>

        </form>

    )
}
