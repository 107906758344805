import { Fragment, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

export const AutocompleteSector = ({ parroquias, valueSector, setValueSector }) => {


    const [query, setQuery] = useState('')

    const filteredSector =
        query === ''
            ? parroquias
            : parroquias.filter((item) =>
                item.sector
                    .toLowerCase()
                    .includes(query.toLowerCase())


            )



    return (
        <Combobox value={valueSector} onChange={setValueSector}>
            <div className="relative mt-1">
                <div className="relative w-full text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-teal-300 focus-visible:ring-offset-2 sm:text-sm overflow-hidden">
                    <Combobox.Input
                        className="bg-gray-200  w-full text-gray-700 focus:outline-none placeholder:text-center border-b-4 border-gray-300 focus:border-gray-600 transition duration-500 px-3 pb-2"
                        displayValue={(item) => item.sector}
                        onChange={(event) => setQuery(event.target.value)}
                        placeholder='Busque el sector'
                        autoComplete='off'
                    />

                </div>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setQuery('')}
                >
                    <Combobox.Options className="absolute  w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-24 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">
                        {filteredSector.length === 0 && query !== '' ? (
                            <div className="cursor-default select-none relative py-2 px-4 text-gray-700">
                                No hay resultados
                            </div>
                        ) : (
                            filteredSector.map((item) => (
                                <Combobox.Option
                                    key={item.sector_id}
                                    className={({ active }) =>
                                        `cursor-default select-none relative py-2 pl-10 pr-4 ${active ? 'text-white bg-red-600' : 'text-gray-900'}`
                                    }
                                    value={item}
                                >
                                    {({ selected, active }) => (
                                        <Fragment>
                                            <span
                                                className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                                            >
                                                {item.sector} - {item.data[0].name}
                                            </span>
                                            {selected ? (
                                                <span
                                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-red-600'}`}
                                                >
                                                    <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                                </span>
                                            ) : null}
                                        </Fragment>
                                    )}
                                </Combobox.Option>
                            ))
                        )}
                    </Combobox.Options>
                </Transition>
            </div>
        </Combobox>
    )
}
