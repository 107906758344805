/**imagenes y logo  */
import Glogo from '../images/logo-g.png';
import portada from '../images/portada.jpeg';
/**Liberia de tailwinds */
import { Tab } from '@headlessui/react'
/**Formularios */
import { FormularioRegistro } from './registro/FormularioRegistro';
import { FormularioConctat } from './conctatanos/FormularioConctat';
/**navegacion */
import { navigation } from '../constants/Navigation';

export const RegistroPage = () => {


    /**Funcion de clases Tailwind */
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }


    return (
        <div>
            <section className='min-h-screen flex items-stretch text-white'>
                <div className='lg:flex w-1/2 hidden bg-gray-500 bg-no-repeat bg-cover relative items-center ' style={{ backgroundImage: `url(${portada})` }}>
                    <div className='absolute bg-black opacity-60 inset-0 z-0'/>
                    <div className='w-full px-24 z-10'>
                      
                        <p className='text-3xl my-4'></p>
                    </div>
                    <div className='bottom-0 absolute p-4 text-center right-0 left-0 flex justify-center space-x-4'/>
                </div>

                <div className='lg:w-1/2 w-full flex items-center justify-center text-center md:px-16 px-0 z-0 bg-black' >
                    <div className='absolute lg:hidden z-10 inset-0 bg-gray-500 bg-no-repeat bg-cover items-center' style={{ backgroundImage: `url(${portada})` }}>
                        <div className='absolute bg-black opacity-60 inset-0 z-0'></div>
                    </div>
                    <div className='w-full py-6 z-20'>
                        <h1 className='my-6'>
                            <img src={Glogo} alt='logo' />
                        </h1>
                        <h4 className=''>
                            N° HGTS-00558
                        </h4>
                        <div className='flex py-6 space-x-4 justify-center'>
                            {navigation.social.map((item) => (
                                <a key={item.name} href={item.href} target="_blank" rel="noreferrer"className="text-gray-400 hover:text-gray-300 ease-linear transition-all duration-300">
                                    <span className="sr-only">{item.name}</span>
                                    <item.icon className="h-6 w-6" aria-hidden="true" />
                                </a>
                            ))}
                        </div>


                        <Tab.Group>
                            <Tab.List className="flex space-x-1 mb-5">
                                <Tab
                                    className={({ selected }) =>
                                        classNames(
                                            'w-full py-2.5 text-base font-medium text-gray-300 rounded-lg',
                                            selected
                                                ? 'bg-white/[0.12] text-white shadow'
                                                : 'text-white hover:bg-white/[0.12] hover:text-white ease-linear transition-all duration-300'
                                        )
                                    }
                                >
                                    REGÍSTRATE
                                </Tab>
                                <Tab
                                    className={({ selected }) =>
                                        classNames(
                                            'w-full py-2.5 text-base font-medium text-gray-300 rounded-lg',
                                            selected
                                                ? 'bg-white/[0.12] text-white shadow'
                                                : 'text-white hover:bg-white/[0.12] hover:text-white ease-linear transition-all duration-300'
                                        )
                                    }
                                >
                                   SUGERENCIAS
                                </Tab>

                            </Tab.List>
                            <Tab.Panels>
                                <Tab.Panel>
                                    <FormularioRegistro />
                                </Tab.Panel>

                                <Tab.Panel>
                                    <FormularioConctat />
                                </Tab.Panel>

                            </Tab.Panels>
                        </Tab.Group>


                    </div>
                </div>
            </section>
        </div>
    )
}