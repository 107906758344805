import { Fragment } from "react";
import { RegistroPage } from "./pages/RegistroPage";

/** Toast */
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    <Fragment>
      <RegistroPage />
      <ToastContainer />
    </Fragment>

  );
}

export default App;
