/** Inicio Validar response error del backend */
export const validError = (err) => {
    const first = getKey(err)
    const error = descapitalizeFirstLetter(err[first][0])
    return `${error}`;
}

/** Obtener el primer Key de un array, sinonimo de [0] */
function getKey(data) {
    for (var prop in data)
        return prop;
}

/** Volver Minuscula la primera letra */
function descapitalizeFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}

/** Opciones del Toast */
export const toastOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
}